export default {
    methods: {
        loadVehicleTypes (isSubscription = false) {
            this.vehicleTypes = [
                { id: 1, title: 'Bike/Scooter', subscriptionAllowed: true },
                { id: 2, title: 'Car / Jeep', subscriptionAllowed: false },
            ].filter(({ subscriptionAllowed }) => isSubscription ? subscriptionAllowed : true)
        }
    }
}
