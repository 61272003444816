<template>
    <div class="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-2 md:p-3 cursor-pointer" @click="$emit('select', city)">
        <div class="w-full bg-white rounded border border-gray-200 p-5 flex items-center">
            <div class="w-12">
            <img src="../assets/icons/city.svg" class="h-12 w-12">
            </div>
            <div class="flex-1 pl-4">
            <h4 class="font-medium text-lg city-title">{{ city.title }}</h4>
            <div class="text-sm text-gray-600">{{ city.parking_areas }} parking {{ city.parking_areas > 1 ? 'areas' : 'area' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        city: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
    .city-title {
        background: -webkit-linear-gradient(280deg, #42d392 20%, #647eff);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>
