<template>
  <nav
    class="w-full flex flex-wrap items-center justify-between py-3"
    :class="{'top-0 absolute z-50': hasBreadcrumbs}"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          class="tracking-wide leading-relaxed flex items-center mr-4 py-2 whitespace-nowrap text-white"
          to="/"
          >
          <img src="../assets/img/logo.svg" class="w-10 md:w-12" />
          <span :class="['ml-4 text-xl lg:text-2xl font-medium roboto-slab tracking-wider', textColor]">Parknsure</span>
        </router-link>
        <!-- <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="text-white fas fa-bars"></i>
        </button> -->
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none"
        v-bind:class="{'hidden': !showMenu, 'block': showMenu}"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <!-- <li class="flex items-center">
            <a
              class="text-white hover:text-gray-300 transition-colors duration-300 px-3 py-4 lg:py-2 flex items-center text-md"
              href="#"
              >
              Search</a
            >
          </li>
          <li class="flex items-center">
            <a
              class="text-white hover:text-gray-300 transition-colors duration-300 px-3 py-4 lg:py-2 flex items-center text-md"
              href="#"
              >
              Offers</a
            >
          </li>
          <li class="flex items-center">
            <a
              class="text-white hover:text-gray-300 transition-colors duration-300 px-3 py-4 lg:py-2 flex items-center text-md"
              href="#"
              >
              Help</a
            >
          </li> -->
          <!-- <li class="flex items-center ml-12">
            <a
              class="lg:text-white lg:hover:text-gray-300 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="text-gray-400 hover:text-gray-100 transition-colors duration-300 fab fa-facebook text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Share</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="text-gray-400 hover:text-gray-100 transition-colors duration-300 fab fa-twitter text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Tweet</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="text-gray-400 hover:text-gray-100 transition-colors duration-300 fab fa-github text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Star</span></a
            >
          </li> -->
          <!-- <li class="flex items-center">
            <button
              class="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              type="button"
              style="transition: all 0.15s ease 0s;"
            >
              <i class="fas fa-sign-in-alt"></i> Login
            </button>
          </li>
          <li class="flex items-center">
            <button
              class="bg-green-500 text-white active:bg-blue-500 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              type="button"
              style="transition: all 0.15s ease 0s;"
            >
              <i class="fas fa-user-alt"></i> Register
            </button>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    hasBreadcrumbs () {
      return !this.$route.meta.noBreadcrumbs
    },
    textColor () {
      return this.hasBreadcrumbs ? 'brand-name' : 'text-gray-700'
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>

<style scoped>
  .brand-name {
    background: -webkit-linear-gradient(315deg, #647eff 25%, #d264ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
