<template>
    <div class="w-full p-4 pb-5 mb-3 bg-white rounded-md border border-gray-200">
        <h3 v-if="$slots.default" class="text-lg leading-4 font-bold text-gray-800 mb-4">
            <slot />
        </h3>
        <form @submit.prevent.stop="onSubmit">
            <div class="flex flex-wrap">
                <div class="w-1/2 pr-2 mb-4" :class="{ 'md:w-1/4 md:mb-0': isOneLine }">
                    <label class="block">
                        <span class="text-gray-700 font-medium text-sm">Your city</span>
                        <select v-model.number="inputs.city" required class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option value="">Select</option>
                            <option
                                v-for="city in cities"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.title }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="w-1/2 pl-2 mb-4" :class="{ 'md:w-1/4 md:pr-2 md:mb-0': isOneLine }">
                    <label class="block">
                        <span class="text-gray-700 font-medium text-sm">Vehicle type</span>
                        <select v-model.number="inputs.type" required class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option v-if="vehicleTypes.length" value="">Select</option>
                            <option
                                v-for="type in vehicleTypes"
                                :key="type.id"
                                :value="type.id"
                            >
                                {{ type.title }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="w-1/2 pr-2" :class="{ 'md:w-1/4 md:pl-2': isOneLine }">
                    <label class="block">
                        <span class="text-gray-700 font-medium text-sm">Vehicle number</span>
                        <input v-model="inputs.number" type="text" maxlength="4" minlength="2" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Last 4 digits">
                    </label>
                </div>
                <div class="w-1/2 pl-2" :class="{ 'md:w-1/4': isOneLine }">
                    <button
                        type="submit"
                        :disabled="isDisabled"
                        class="rounded-md text-white h-10 mt-7 w-full"
                        :class="isDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-gray-800'"
                    >
                        {{ loading ? 'Searching...' : 'Search' }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props:{
        modelValue:{
            type: Object,
            required: true
        },
        vehicleTypes: {
            type: Array,
            required: true
        },
        cities: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        isOneLine: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            inputs: this.modelValue
        }
    },
    computed: {
        isDisabled () {
            return this.loading || !this.cities.length || !this.vehicleTypes.length
        }
    },
    watch: {
        'inputs.number' (val) {
            this.inputs.number = val.replace(/[^\d.-]+/g, '')
        }
    },
    methods: {
        onSubmit () {
            window.localStorage.setItem('vehicle_type', this.inputs.type)
            window.localStorage.setItem('vehicle_number', this.inputs.number)
            const city = this.cities.find(({ id }) => id === this.inputs.city)
            if (city) {
                window.localStorage.setItem('city', JSON.stringify(city))
            }
            this.$emit('submit')
        }
    }
}
</script>
