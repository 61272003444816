<template>
  <footer class="relative bg-white py-6 border-t border-gray-200">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-3/5 flex flex-wrap">
          <div class="w-full sm:w-1/2 py-4 lg:pr-5">
            <div class="flex items-center">
              <img src="../assets/img/logo.svg" class="w-8" />
              <span class="ml-3 text-xl font-medium roboto-slab tracking-wide">Parknsure</span>
            </div>
            <p class="mt-4 text-md text-gray-600">
              Parknsure is a comprehensive parking management application designed to streamline 
              operations for parking owners and provide a seamless experience for the public.
            </p>
          </div>
          <div class="w-full sm:w-1/2 py-4 lg:pl-5">
            <h4
              class="block text-gray-800 text-md font-semibold mb-2"
              >Regd. Office Address</h4
            >
            <p class="mt-2 text-sm leading-6 text-gray-600">
              Inhok Technologies Private Limited<br>
              Room no. 205, Students Amenity Centre<br>
              Thavakkara, Kannur<br>
              Kerala, India - 670001<br>
              <span class="font-medium">CIN: U72900KL2022PTC077260</span>
            </p>
          </div>
        </div>
        <div class="w-full lg:w-2/5 flex flex-wrap">
          <div class="w-full sm:w-1/2 py-4">
            <h4
              class="block text-gray-800 text-md font-semibold mb-2"
              >General links</h4
            >
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/about-us"
                  >About Us</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/"
                  >Blog</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/our-team"
                  >Our Team</router-link
                >
              </li> -->
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/business-solutions"
                  >Business solutions</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/contact-us"
                  >Contact us</router-link
                >
              </li>
              <li>
                <a href="#"
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  onclick="window.displayPreferenceModal();return false;"
                  id="termly-consent-preferences"
                >Consent Preferences</a>
              </li>
            </ul>
          </div>
          <div class="w-full sm:w-1/2 py-4">
            <h4
              class="block text-gray-800 text-md font-semibold mb-2"
              >Legal stuff</h4
            >
            <ul class="list-unstyled">
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/terms-of-use"
                  >Terms Of Use</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/privacy-policy"
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/cookie-policy"
                  >Cookie Policy</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/return-policy"
                  >Return Policy</router-link
                >
              </li> -->
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/shipping-policy"
                  >Shipping & delivery</router-link
                >
              </li>
              <li>
                <router-link
                  class="text-gray-600 hover:text-gray-800 block pb-2 text-sm"
                  to="/refund-policy"
                  >Refund Policy</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-6 border-gray-200" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-700 font-light py-1">
            Copyright © {{date}} <span class="text-gray-900 font-medium">Parknsure</span>. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>
