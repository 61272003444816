export default {
    methods: {
        loadAvailableCities() {
            const cities = JSON.parse(window.localStorage.getItem('cities') || '{}');
            if (!Object.keys(cities).includes('expiry') || this.cities.expiry <= new Date().getTime()) {
              fetch(`${process.env.VUE_APP_API_ROOT}list-cities`, {
                  method: "POST", // *GET, POST, PUT, DELETE, etc.
                  mode: "no-cors"
                })
                .then(response => response.json())
                .then(({ success, data }) => {
                  if (success) {
                    const result = {
                      data,
                      expiry: new Date().getTime() + (24 * 60 * 60 * 1000) // 1 day
                    }
                    window.localStorage.setItem('cities', JSON.stringify(result));
                    this.cities = data
                  }
                });
              
            } else {
              this.cities = cities.data
            }
        }
    }
}
