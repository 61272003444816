<template>
    <div class="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-2 md:p-3">
        <div class="w-full bg-white rounded border border-gray-200 p-5 flex items-center animate-pulse">
            <div class="w-12">
                <div class="h-12 w-12 bg-gray-200 rounded"></div>
            </div>
            <div class="flex-1 pl-4">
                <div class="h-3 bg-gray-200 rounded w-1/3"></div>
                <div class="h-3 bg-gray-200 rounded w-5/6 mt-3"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        city: {
            type: Object,
            required: true
        }
    }
}
</script>